import React from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import gestionale2 from "../../assets/images/blog-image/gestionale2.png";
import gestionale3 from "../../assets/images/blog-image/gestionale3.png";
import gestionale4 from "../../assets/images/blog-image/gestionale4.png";
import gestionale5 from "../../assets/images/blog-image/gestionale5.png";
import gestionale6 from "../../assets/images/blog-image/gestionale6.png";
import gestionale7 from "../../assets/images/blog-image/gestionale7.png";
import gestionale8 from "../../assets/images/blog-image/gestionale8.png";
import appius from "../../images/appius_logo.png";

import Blog from "../../assets/json/blog.json";

const articleData = {
    id: 4,
    title: "Software gestionale personalizzato. 6 ottimi motivi per sceglierlo",
    desc:
        "Immagina di pensare ad un’azienda moderna e competitiva. Quali sono i primi elementi che ti vengono in mente?",
    img: "/blog-image/gestionale1.png",
    imgs: [
        "/blog-image/react_native.png",
        "/blog-image/app.png",
        "/blog-image/app2.png",
    ],
    page: "blog/gestionale-personalizzato",
    data: "13 Dic 2022",
    author: "Staff",
};

const BlogDetails = () => (
    <Layout>
        <SEO
            title={articleData.title}
            meta={[
                {
                    name: `description`,
                    content:
                        "Immagina di pensare ad un’azienda moderna e competitiva. Quali sono i primi elementi che ti vengono in mente?",
                },
                {
                    property: `og:title`,
                    content: articleData.title,
                },
            ]}
        />

        <Navbar />

        <PageBanner pageTitle={articleData.title} />

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img
                                    src={
                                        require("../../assets/images" +
                                            articleData.img).default
                                    }
                                    alt="image"
                                />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> {articleData.data}
                                        </li>
                                        <li>
                                            <Icon.User />{" "}
                                            <Link to="#">
                                                {articleData.author}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <p className="pt-3 pb-3 fs-4">
                                    Immagina di pensare ad un’azienda moderna e
                                    competitiva. Quali sono i primi elementi che
                                    ti vengono in mente? Una struttura dove il
                                    reperimento e la gestione dei dati viene
                                    semplificata, dove per ogni esigenza
                                    aziendale c’è anche una soluzione ad hoc e
                                    innovativa.
                                </p>
                                <p>
                                    Tra i principali obiettivi di ogni manager e
                                    proprietario d’impresa c’è sicuramente
                                    quello di ridurre i costi - possibilmente
                                    aumentando di pari passo la produttività. Se
                                    da un lato c’è la tendenza a “temprare” i
                                    propri dipendenti, valutare se sono in grado
                                    di districarsi su più fronti; dall’altro c’è
                                    la necessità di supportare il proprio team
                                    fornendogli tutti gli strumenti per svolgere
                                    l’attività richiesta nel modo più agevole
                                    possibile. E, perché no, magari usando
                                    strumenti all'avanguardia in grado di dare
                                    all’azienda un tocco di modernità. Come
                                    avvicinarsi a questi obiettivi di gestione?
                                    Sicuramente partendo dall’utilizzo di un
                                    software sviluppato su misura.
                                </p>
                                <p>
                                    Parola chiave: Organizzazione. Vogliamo
                                    partire da qui per parlare del perché, per
                                    essere competitivi e funzionali, optare per
                                    un gestionale personalizzato risulta essere
                                    la scelta ottimale. “Organizzazione”
                                    racchiude in sé le innumerevoli azioni
                                    quotidiane che i lavoratori effettuano,
                                    dalla più banale e rapida a quella più
                                    ragionata e importante. Quanto, quindi, può
                                    risultare importante avere un tool in grado
                                    di tracciare e supportare tali azioni, che
                                    rappresentano in tutto e per tutto il vero
                                    fulcro della vita aziendale? Il mercato è in
                                    continua evoluzione e gli investimenti in
                                    soluzioni informatiche diventano molto
                                    spesso un must su cui basare la stessa
                                    sopravvivenza del business. I competitors e
                                    le nuove richieste da parte dei clienti
                                    fanno convergere l’attenzione sul tech,
                                    allontanandosi sempre di più da ciò che
                                    viene svolto in autonomia - senza l’aiuto di
                                    un supporto informatico - e a mano su carta.
                                    Per una questione di sicurezza, puntualità e
                                    precisione si tende ad optare per un
                                    software gestionale con l’obiettivo di
                                    supportare l’attività dei propri
                                    collaboratori e rendere il tutto più
                                    agevole. Un gestionale permette ai
                                    lavoratori di portare avanti il loro lavoro
                                    in nome dell’efficienza, determinando una
                                    riduzione dei costi e permettendo
                                    all’azienda di - con il tempo risparmiato
                                    grazie al software - concentrarsi su
                                    ulteriori attività.
                                </p>
                                <p>
                                    Vuoi migliorare la gestione del tuo
                                    magazzino? Hai bisogno di supporto per
                                    fatture, preventivi e spedizioni? Desideri
                                    una reportistica più accessibile? Queste
                                    sono solo alcune delle attività che
                                    diventerebbero più facili con un software.
                                    Niente però è scontato. La scelta più
                                    conveniente ed innovativa molto spesso viene
                                    vista come un ostacolo insuperabile. Per
                                    molti il cambiamento è qualcosa di
                                    spaventoso e la digitalizzazione tutt’altro
                                    che ammirata. Per questo la cosa più
                                    importante da sottolineare è che non sarai
                                    mai solo in questo processo!
                                </p>

                                <img src={gestionale2} alt="" />

                                <p>
                                    Sviluppiamo per te un gestionale su misura,
                                    puntando su qualità del prodotto e elevato
                                    grado tecnologico, soffermandoci anche sul
                                    design dell’interfaccia per un’esperienza
                                    piacevole dal lato utente.
                                </p>

                                <p>
                                    Di seguito, una guida di 6 punti - a prova
                                    di indecisi - per guidare le aziende verso
                                    la decisione di innovarsi, scegliendo un
                                    gestionale custom. Una soluzione software
                                    sviluppata appositamente sulle esigenze del
                                    proprio business con l’obiettivo di
                                    agevolare il raggiungimento dei goal di
                                    gestione e miglioramento dell’intera
                                    organizzazione.
                                </p>

                                <ol className="pt-3 pb-3">
                                    <li>
                                        <h4>Competitivit&aacute;:</h4>
                                        <img src={gestionale3} alt="" />
                                        <p className="pb-3 pt-3">
                                            per essere competitivi e funzionali,
                                            i comuni gestionali non sono più
                                            sufficienti. Un sistema su misura è
                                            invece in grado si rispondere
                                            perfettamente alle esigenze
                                            dell’azienda ed offrire un elevato
                                            grado di efficienza e ordine
                                            all’intera organizzazione. Supera i
                                            competitor snellendo e ottimizzando
                                            i processi di gestione aziendale!
                                        </p>
                                    </li>
                                    <li>
                                        <h4>Consultazione dati:</h4>
                                        <img src={gestionale4} alt="" />
                                        <p className="pb-3 pt-3">
                                            la generazione di report, la
                                            consultazione di statistiche e
                                            l’analisi dei dati più in generale
                                            diventa decisamente più veloce e
                                            semplice. La gestione dei dati,
                                            inoltre, si lega anche alle regole
                                            di trattamento degli stessi. Anche
                                            in questo caso, il gestionale custom
                                            va incontro all’azienda
                                            predisponendo piani adeguati che
                                            rispettino le normative sulla
                                            privacy.
                                        </p>
                                    </li>
                                    <li>
                                        <h4>Risparmio di tempo:</h4>
                                        <img src={gestionale5} alt="" />
                                        <p className="pb-3 pt-3">
                                            tutto quello che prima veniva fatto
                                            a mano o tramite l’utilizzo di più
                                            strumenti, ora può essere eseguito
                                            con un singolo tool. “Il tempo è
                                            denaro” dice un famoso detto. Ma non
                                            solo, perché, oltre alla riduzione
                                            dei costi dovuta all’inserimento di
                                            tutte le funzioni in un’unica
                                            soluzione, il risparmio di tempo
                                            generato dall’utilizzo del software
                                            va direttamente ad accrescere il
                                            tempo destinato alla cura di altre
                                            attività utili.
                                        </p>
                                    </li>
                                    <li>
                                        <h4>
                                            Facilit&aacute; di sincronizzazione:
                                        </h4>
                                        <img src={gestionale6} alt="" />
                                        <p className="pb-3 pt-3">
                                            affidarsi ad un partner competente
                                            per la realizzazione di un
                                            gestionale personalizzato è
                                            fondamentale per avere uno strumento
                                            efficiente. Ci basterà pochissimo
                                            tempo per trasportare tutti i tuoi
                                            dati nel nuovo gestionale. Così da
                                            essere pronto all’uso e con tutte le
                                            informazioni per iniziare a lavorare
                                            in modo ottimale.
                                        </p>
                                    </li>
                                    <li>
                                        <h4>
                                            Gestisci la tua organizzazione con
                                            facilit&aacute;:
                                        </h4>
                                        <img src={gestionale7} alt="" />
                                        <p className="pb-3 pt-3">
                                            un singolo software per avere tutte
                                            le attività a portata di click!
                                            Gestione fatture, magazzino,
                                            preventivi, ordini e tutto quello
                                            richiesto dalla tua azienda
                                            racchiuso in un unico strumento
                                            accessibile sempre a tutti. Lascia i
                                            meccanismi laboriosi da una parte e
                                            punta su un tool intuitivo!
                                        </p>
                                    </li>
                                    <li>
                                        <h4>Finalmente un pò di ordine!</h4>
                                        <img src={gestionale8} alt="" />
                                        <p className="pb-3 pt-3">
                                            Dì addio ai post-it e agli appunti
                                            andati persi o buttati per sbaglio
                                            nella spazzatura. La tua
                                            organizzazione sarà riassunta in
                                            ordinate schermate. I dati dei tuoi
                                            clienti e dei fornitori racchiusi in
                                            cassetti virtuali, organizzati in
                                            modo preciso e dettagliato.
                                        </p>
                                    </li>
                                </ol>

                                <p>
                                    Non accontentarti di soluzioni
                                    standardizzate che devi sforzarti di fare
                                    tue, adattandole alle necessità della tua
                                    azienda. Strumenti come Excel o Fogli
                                    offrono la possibilità di raccogliere dati
                                    senza darti modo di creare una continuità
                                    nella consultazione o con mille formule da
                                    ricordare prima di avere davanti una
                                    schermata che si avvicina a quello che avevi
                                    in mente. Investi in uno strumento su misura
                                    e migliora la produttività dei dipendenti e
                                    la potenzialità delle azioni quotidiane. La
                                    tecnologia ti spaventa? Pensi di non
                                    riuscire da solo ad immergerti - magari per
                                    la prima volta - in un software? O, ancora,
                                    credi che alcuni dei tuoi dipendenti possano
                                    riscontrare delle difficoltà nell'adattarsi
                                    al nuovo sistema? Ti seguiamo noi! Vogliamo
                                    clienti soddisfatti, che padroneggiano al
                                    meglio i tool che forniamo. Scuse come “Ma
                                    io sono abituato così, non mi va di
                                    cambiare” non devono poter intralciare il
                                    processo - quasi obbligato dalle esigenze di
                                    mercato - di digitalizzazione della tua
                                    azienda.
                                </p>
                                <p>
                                    Contattaci oggi e parlaci delle esigenze del
                                    tuo business: progettiamo la soluzione
                                    software per la tua impresa, strutturata
                                    secondo i tuoi obiettivi.
                                    <br />
                                    <br />
                                    Scrivici a{" "}
                                    <a
                                        className="text-primary"
                                        href="mailto:info@appius.it"
                                    >
                                        info@appius.it
                                    </a>{" "}
                                    o chiamaci allo{" "}
                                    <a
                                        className="text-primary"
                                        href="tel:+3905361856617"
                                    >
                                        (+39) 0536 1856617
                                    </a>
                                    .
                                </p>
                                <img src={appius} alt="" />
                            </div>

                            <div
                                style={{ marginTop: 30 }}
                                className="startp-post-navigation"
                            >
                                {Blog.articles
                                    .filter((a) => a.id != articleData.id)
                                    .slice(0, 2)
                                    .map((article) => {
                                        return (
                                            <div className="prev-link-wrapper">
                                                <div className="info-prev-link-wrapper">
                                                    <Link
                                                        to={"/" + article.page}
                                                    >
                                                        <span className="image-prev">
                                                            <img
                                                                src={
                                                                    require("../../assets/images" +
                                                                        article.img)
                                                                        .default
                                                                }
                                                                alt="image"
                                                            />
                                                            <span className="post-nav-title">
                                                                VAI
                                                            </span>
                                                        </span>

                                                        <span className="prev-link-info-wrapper">
                                                            <span className="prev-title">
                                                                {article.title}
                                                            </span>
                                                            <span className="meta-wrapper">
                                                                <span className="date-post">
                                                                    {
                                                                        article.data
                                                                    }
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar id={articleData.id} />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
);

export default BlogDetails;
